import { BaseEntity } from './BaseEntity';
import { PartialPhoto } from './Photo';

export enum ModelGender {
  Male = 'male',
  Female = 'female'
}

export interface Model extends BaseEntity {
  name: string
  avatar: string
  heightFt?: number
  heightIn?: number
  weightLbs?: number
  measurementsChest?: string
  measurementsWaist?: number
  measurementsHips?: number
  birthPlace?: string
  // birthDay?: string
  description?: string
  gender: ModelGender
  isPopular: boolean
  avatarLink: string
  videosCount: number
  photos: PartialPhoto[]
  tooltips: string
}

export type PartialModel = Partial<Model>;
